import axios from 'axios';
import fileDownload from 'js-file-download';

export const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
}

export const list = [
    {
        bold: true,
        num: "8%",
        pay: "5 PAGOS",
        tab: [
            { item1: "· Primer pago", item2: "22.5%"},
            { item1: "· Segundo pago", item2: "22.5%"},
            { item1: "· Tercer pago", item2: "22.5%"},
            { item1: "· Cuarto pago", item2: "22.5%"},
            { item1: "· Último pago contra escritura", item2: "10%"},
        ]
    },
    {
        bold: false,
        num: "6%",
        pay: "15 PAGOS",
        tab: [
            { item1: "· Primer pago enganche", item2: "15%"},
            { item1: "· Segundo pago enganche", item2: "15%"},
            { item1: "· Pagos diferidos 12 exhibiciones", item2: "50%"},
            { item1: "· Último pago contra escritura", item2: "20%"},
           
        ]
    },
    {
        bold: false,
        num: "4%",
        pay: "21 PAGOS",
        tab: [
            { item1: "· Primer pago enganche", item2: "10%"},
            { item1: "· Segundo pago enganche", item2: "10%"},
            { item1: "· Pagos diferidos 18 exhibiciones", item2: "50%"},
            { item1: "· Último pago contra escritura", item2: "30%"},
        ]
    },
    {
        bold: false,
        num: "2%",
        pay: "28 PAGOS",
        tab: [
            { item1: "· Primer pago enganche", item2: "10%"},
            { item1: "· Segundo pago enganche", item2: "5%"},
            { item1: "· Pagos diferidos 25 exhibiciones", item2: "35%"},
            { item1: "· Último pago contra escritura", item2: "50%"}
        ]
    },
    {
        bold: false,
        num: "0%",
        pay: "28 PAGOS",
        tab: [
            { item1: "· Primer pago enganche", item2: "5%"},
            { item1: "· Segundo pago enganche", item2: "5%"},
            { item1: "· Pagos diferidos 25 exhibiciones", item2: "20%"},
            { item1: "· Último pago contra escritura", item2: "70%"}
        ]
    }
  ];