import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Stack,
    Text,
    Image,
    VStack,
    FormControl,
    Input,
    InputGroup,
    InputLeftAddon,
    Spinner,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerContent,
    Show
} from '@chakra-ui/react';
import { handleDownload } from '../resource';
import { toast } from 'react-toastify';
import brochure from '../resource/pdf/Ambienta3070_BrochureDigital.pdf';

const ModalCard = ({ isOpen, onClose, title, idForm, onCloseDrawer, isOpenDrawer, facebook }) => {
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        nombre: "",
        apellidos: "",
        telefono: "",
        email: "",
        nombreError: false,
        apellidoError: false,
        telefonoError: "",
        emailError: false,
    });

    const icons = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/soare/icons/logo_guia.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/soare/icons/mexico.svg' }
    ];

    const handleSubmit = () => {
        setLoading(true);
        const valuesForm = [
            {
                name: "firstname",
                value: values.nombre,
            },
            {
                name: "lastname",
                value: values.apellidos,
            },
            {
                name: "email",
                value: values.email,
            },
            {
                name: "phone",
                value: values.telefono,
            }
        ];
        const body = {
            fields: valuesForm,
            context: {
                ipAddress: "3.14.97.137",
                pageUri: "www.grupoguia.mx/contacto",
                pageName: "Grupo Guia | Contacto",
            },
        };
        fetch(
            "https://api.hsforms.com/submissions/v3/integration/submit/5930840/" + idForm,
            {
                method: "POST",
                body: JSON.stringify(body),
                headers: { "Content-Type": "application/json" },
            }
        ).then((response) => {
            if (title === "Descargar brochure") {
                handleDownload(brochure, "Ambienta3070_Brochure_Digital.pdf");
            } else if (title === "Envíanos un Whatsapp") {
                if (facebook) {
                    window.location.href = "https://api.whatsapp.com/send/?phone=5213328337612";
                } else {
                    const typOpt = `thankyoupage-contactar-whatsapp/5213328337612`
                    window.location.href = `https://grupoguia.mx/desarrollos/${typOpt}`;
                }
            }
            toast.success("¡Tus datos fueron enviados correctamente!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            window.open('https://grupoguia.mx/desarrollos/thankyoupage-agenda-cita', '_blank');
            setLoading(false);
            onClose();
        });
    }

    return (
        <>
            {!facebook ? (
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader display={"flex"} justifyContent={"center"} paddingBottom={"0px"}>
                            <Image src={icons[0].imageUrl} w={24} />
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Stack textAlign={"center"} alignItems="center">
                                <Text fontSize={"xl"} fontWeight={600}>
                                    {title}
                                </Text>
                            </Stack>
                            <VStack
                                borderRadius="lg"
                                margin="auto"
                                maxW={"100%"}
                                py={12}
                            >
                                <form
                                    style={{ width: '80%' }}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}>
                                    <FormControl mb={3}>
                                        <Input
                                            onInvalid={() => setValues({ ...values, nombreError: true })}
                                            rounded="full"
                                            placeholder="Nombre(s)"
                                            marginBottom="10px"
                                            id="nombres"
                                            type="text"
                                            py={5}
                                            value={values.nombre}
                                            isRequired
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setValues({ ...values, nombre: e.target.value });
                                            }}
                                            onBlur={(e) => {
                                                e.preventDefault();
                                                setValues({ ...values, nombre: e.target.value });
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl mb={3}>
                                        <Input
                                            onInvalid={() => setValues({ ...values, apellidoError: true })}
                                            rounded="full"
                                            placeholder="Apellidos"
                                            marginBottom="10px"
                                            value={values.apellidos}
                                            id="apellidos"
                                            type="text"
                                            py={5}
                                            isRequired
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setValues({ ...values, apellidos: e.target.value });
                                            }}
                                            onBlur={(e) => {
                                                e.preventDefault();
                                                setValues({ ...values, apellidos: e.target.value });
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl mb={3}>
                                        <InputGroup marginBottom="20px">
                                            <InputLeftAddon
                                                backgroundColor="#FFFFFF"
                                                rounded="full"
                                                padding="15px 6px 15px 15px"
                                                py={5}
                                                // eslint-disable-next-line jsx-a11y/alt-text
                                                children={
                                                    <img src={icons[1].imageUrl} alt='icon' width="16" height="16" />
                                                }
                                            />
                                            <InputLeftAddon
                                                backgroundColor="#FFFFFF"
                                                borderLeftColor="#FFFFFF"
                                                borderRadius="0px"
                                                padding="0px"
                                                children="+52"
                                                paddingRight="10px"
                                                py={5}
                                            />
                                            <Input
                                                onInvalid={() => setValues({ ...values, telefonoError: true })}
                                                borderRadius="24px"
                                                placeholder="Teléfono"
                                                type="number"
                                                id="telefono"
                                                py={5}
                                                value={values.telefono}
                                                isRequired
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setValues({ ...values, telefono: e.target.value });
                                                }}
                                                onBlur={(e) => {
                                                    e.preventDefault();
                                                    setValues({ ...values, telefono: e.target.value });
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mb={3}>
                                        <Input
                                            onInvalid={() => setValues({ ...values, emailError: true })}
                                            rounded="full"
                                            placeholder="Correo electrónico"
                                            marginBottom="10px"
                                            value={values.email}
                                            id="email"
                                            type="email"
                                            py={5}
                                            isRequired
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setValues({ ...values, email: e.target.value });
                                            }}
                                            onBlur={(e) => {
                                                e.preventDefault();
                                                setValues({ ...values, email: e.target.value });
                                            }}
                                        />
                                    </FormControl>
                                    <Button
                                        bg={"#EDBE00"}
                                        rounded="full"
                                        w="100%"
                                        fontWeight="500"
                                        color="black"
                                        borderColor="#FFCD00"
                                        boxShadow={"rgb(221, 221, 221) 0px 4px 8px 0px"}
                                        _hover={{
                                            bg: "#EDBE00",
                                        }}
                                        type="submit"
                                    >
                                        {loading ?
                                            <Spinner
                                                thickness='4px'
                                                speed='0.65s'
                                                emptyColor='gray.200'
                                                color='#fff'
                                                size='lg'
                                            />
                                            :
                                            <span>Enviar</span>
                                        }
                                    </Button>
                                </form>
                            </VStack>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            ) : (
                <>
                    {/* ===== DRAWER ONLY FOR USERS MOBILE FROM FACEBOOK ===== */}
                    <Show below='sm'>
                        <Drawer placement='bottom' onClose={onCloseDrawer} isOpen={isOpenDrawer}>
                            <DrawerContent>
                                <DrawerHeader></DrawerHeader>
                                <DrawerBody>
                                    <Stack textAlign={"center"} alignItems="center">
                                        <Image src={icons[0].imageUrl} w={24} />
                                        <Text fontSize={"xl"} fontWeight={600}>
                                            {title}
                                        </Text>
                                    </Stack>
                                    <VStack
                                        borderRadius="lg"
                                        margin="auto"
                                        maxW={"100%"}
                                        py={12}
                                    >
                                        <form
                                            style={{ width: '80%' }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSubmit();
                                            }}>
                                            <FormControl mb={3}>
                                                <Input
                                                    onInvalid={() => setValues({ ...values, nombreError: true })}
                                                    rounded="full"
                                                    placeholder="Nombre(s)"
                                                    marginBottom="10px"
                                                    id="nombres"
                                                    type="text"
                                                    py={5}
                                                    value={values.nombre}
                                                    isRequired
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, nombre: e.target.value });
                                                    }}
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, nombre: e.target.value });
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl mb={3}>
                                                <Input
                                                    onInvalid={() => setValues({ ...values, apellidoError: true })}
                                                    rounded="full"
                                                    placeholder="Apellidos"
                                                    marginBottom="10px"
                                                    value={values.apellidos}
                                                    id="apellidos"
                                                    type="text"
                                                    py={5}
                                                    isRequired
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, apellidos: e.target.value });
                                                    }}
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, apellidos: e.target.value });
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl mb={3}>
                                                <InputGroup marginBottom="20px">
                                                    <InputLeftAddon
                                                        backgroundColor="#FFFFFF"
                                                        rounded="full"
                                                        padding="15px 6px 15px 15px"
                                                        py={5}
                                                        // eslint-disable-next-line jsx-a11y/alt-text
                                                        children={
                                                            <img src={icons[1].imageUrl} alt='icon' width="16" height="16" />
                                                        }
                                                    />
                                                    <InputLeftAddon
                                                        backgroundColor="#FFFFFF"
                                                        borderLeftColor="#FFFFFF"
                                                        borderRadius="0px"
                                                        padding="0px"
                                                        children="+52"
                                                        paddingRight="10px"
                                                        py={5}
                                                    />
                                                    <Input
                                                        onInvalid={() => setValues({ ...values, telefonoError: true })}
                                                        borderRadius="24px"
                                                        placeholder="Teléfono"
                                                        type="number"
                                                        id="telefono"
                                                        py={5}
                                                        value={values.telefono}
                                                        isRequired
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setValues({ ...values, telefono: e.target.value });
                                                        }}
                                                        onBlur={(e) => {
                                                            e.preventDefault();
                                                            setValues({ ...values, telefono: e.target.value });
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormControl>
                                            <FormControl mb={3}>
                                                <Input
                                                    onInvalid={() => setValues({ ...values, emailError: true })}
                                                    rounded="full"
                                                    placeholder="Correo electrónico"
                                                    marginBottom="10px"
                                                    value={values.email}
                                                    id="email"
                                                    type="email"
                                                    py={5}
                                                    isRequired
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, email: e.target.value });
                                                    }}
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, email: e.target.value });
                                                    }}
                                                />
                                            </FormControl>
                                            <Button
                                                bg={"#EDBE00"}
                                                rounded="full"
                                                w="100%"
                                                fontWeight="500"
                                                color="black"
                                                borderColor="#FFCD00"
                                                boxShadow={"rgb(221, 221, 221) 0px 4px 8px 0px"}
                                                _hover={{
                                                    bg: "#EDBE00",
                                                }}
                                                type="submit"
                                            >
                                                {loading ?
                                                    <Spinner
                                                        thickness='4px'
                                                        speed='0.65s'
                                                        emptyColor='gray.200'
                                                        color='#fff'
                                                        size='lg'
                                                    />
                                                    :
                                                    <span>Enviar</span>
                                                }
                                            </Button>
                                        </form>
                                    </VStack>
                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>
                    </Show>
                    <Show above='sm'>
                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader display={"flex"} justifyContent={"center"} paddingBottom={"0px"}>
                                    <Image src={icons[0].imageUrl} w={24} />
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Stack textAlign={"center"} alignItems="center">
                                        <Text fontSize={"xl"} fontWeight={600}>
                                            {title}
                                        </Text>
                                    </Stack>
                                    <VStack
                                        borderRadius="lg"
                                        margin="auto"
                                        maxW={"100%"}
                                        py={12}
                                    >
                                        <form
                                            style={{ width: '80%' }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSubmit();
                                            }}>
                                            <FormControl mb={3}>
                                                <Input
                                                    onInvalid={() => setValues({ ...values, nombreError: true })}
                                                    rounded="full"
                                                    placeholder="Nombre(s)"
                                                    marginBottom="10px"
                                                    id="nombres"
                                                    type="text"
                                                    py={5}
                                                    value={values.nombre}
                                                    isRequired
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, nombre: e.target.value });
                                                    }}
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, nombre: e.target.value });
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl mb={3}>
                                                <Input
                                                    onInvalid={() => setValues({ ...values, apellidoError: true })}
                                                    rounded="full"
                                                    placeholder="Apellidos"
                                                    marginBottom="10px"
                                                    value={values.apellidos}
                                                    id="apellidos"
                                                    type="text"
                                                    py={5}
                                                    isRequired
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, apellidos: e.target.value });
                                                    }}
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, apellidos: e.target.value });
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl mb={3}>
                                                <InputGroup marginBottom="20px">
                                                    <InputLeftAddon
                                                        backgroundColor="#FFFFFF"
                                                        rounded="full"
                                                        padding="15px 6px 15px 15px"
                                                        py={5}
                                                        // eslint-disable-next-line jsx-a11y/alt-text
                                                        children={
                                                            <img src={icons[1].imageUrl} alt='icon' width="16" height="16" />
                                                        }
                                                    />
                                                    <InputLeftAddon
                                                        backgroundColor="#FFFFFF"
                                                        borderLeftColor="#FFFFFF"
                                                        borderRadius="0px"
                                                        padding="0px"
                                                        children="+52"
                                                        paddingRight="10px"
                                                        py={5}
                                                    />
                                                    <Input
                                                        onInvalid={() => setValues({ ...values, telefonoError: true })}
                                                        borderRadius="24px"
                                                        placeholder="Teléfono"
                                                        type="number"
                                                        id="telefono"
                                                        py={5}
                                                        value={values.telefono}
                                                        isRequired
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            setValues({ ...values, telefono: e.target.value });
                                                        }}
                                                        onBlur={(e) => {
                                                            e.preventDefault();
                                                            setValues({ ...values, telefono: e.target.value });
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormControl>
                                            <FormControl mb={3}>
                                                <Input
                                                    onInvalid={() => setValues({ ...values, emailError: true })}
                                                    rounded="full"
                                                    placeholder="Correo electrónico"
                                                    marginBottom="10px"
                                                    value={values.email}
                                                    id="email"
                                                    type="email"
                                                    py={5}
                                                    isRequired
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, email: e.target.value });
                                                    }}
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        setValues({ ...values, email: e.target.value });
                                                    }}
                                                />
                                            </FormControl>
                                            <Button
                                                bg={"#EDBE00"}
                                                rounded="full"
                                                w="100%"
                                                fontWeight="500"
                                                color="black"
                                                borderColor="#FFCD00"
                                                boxShadow={"rgb(221, 221, 221) 0px 4px 8px 0px"}
                                                _hover={{
                                                    bg: "#EDBE00",
                                                }}
                                                type="submit"
                                            >
                                                {loading ?
                                                    <Spinner
                                                        thickness='4px'
                                                        speed='0.65s'
                                                        emptyColor='gray.200'
                                                        color='#fff'
                                                        size='lg'
                                                    />
                                                    :
                                                    <span>Enviar</span>
                                                }
                                            </Button>
                                        </form>
                                    </VStack>
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                    </Show>
                </>
            )}
        </>
    );
}

export default ModalCard;