import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
  Divider,
  Button
} from '@chakra-ui/react';
import {
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  HamburgerIcon,
} from '@chakra-ui/icons';
import "../styles/styled.css";
import '../styles/nav.css';
import axios from 'axios';
import fileDownload from 'js-file-download';
import brochure from '../resource/pdf/Ambienta3070_BrochureDigital.pdf';

export default function Nav() {
  const { isOpen, onToggle } = useDisclosure();

  const icons = [
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/logo.svg' }
  ];

  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }

  return (
    <Box>
      <Flex id='mobile'
        bg={useColorModeValue('transparent', 'gray.800')}
        color={useColorModeValue('gray.600', 'gray.600')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={'center'}>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }} >
          <Image src={icons[0].imageUrl} boxSize='80px' alt="logo" />
          <Flex display={{ base: 'none', md: 'none', lg: 'flex' }} ml={20}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'center'}
          direction={'row'}
          marginRight={{ base: 0, md: 6 }}
          spacing={2}>
          <Button
            fontSize='sm'
            bg='#5D7261'
            _hover={{
              bg: '#3F4D41'
            }}
            onClick={() => handleDownload(brochure, 'Ambienta3070_Brochure_Digital.pdf')}
          >
            Descargar brochure
          </Button>
        </Stack>

        <Flex
          flex={{ base: 1, md: 'auto' }}
          justifyContent={"center"}
          display={{ base: 'flex', lg: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} color='#6F6F6F' /> : <HamburgerIcon w={5} h={5} color='#6F6F6F' />

            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />

        </Flex>
      </Flex>

      <Divider
        id='divisor'
        display={{ base: 'flex', lg: 'none' }}
        width={'100%'}
        boxShadow={'2px 2px 2px #000000'}
      />

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('#5D7261', 'gray.100');
  const linkHoverColor = useColorModeValue('#545454', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4} alignItems='center'>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label} alignItems='center'>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger id="popover" textAlign={"center"}>
              <Link
                href={navItem.href ?? '#'}
                fontSize={"16px"}
                fontWeight={500}
                fontFamily={"Montserrat, sans-serif"}
                color={linkColor}
                pr={10}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}

              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>

    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      p={4}
      display={{ lg: 'none' }}>

      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />

      ))}

    </Stack>

  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={'#5D7261'}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}

      </Flex>


      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: 'Proyecto',
    href: '#proyecto',
  },
  {
    label: 'Amenidades',
    href: '#amenidades',
  },
  {
    label: 'Prototipos',
    href: '#prototipos',
  },
  {
    label: 'TyA',
    href: '#tya',
  },
  {
    label: 'Contacto',
    href: '#contacto',
  }
];