import { Flex, Image, Text, Grid, GridItem} from '@chakra-ui/react';
import '../styles/styled.css';
import  CircleIcon  from '../assets/svgs/CircleSVG';

const assets1 = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/gym_interior.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/coworking.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/gym_exterior.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/hamacas.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/juegos.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/lobby.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/pergolado.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/sky bar.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/social+room.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/terraza.png' }
];

const assets2 = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/serena.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/essencia.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/mila.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/pacifica.png' }
];

const assets3 = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/ventura.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/soare.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/classiqa.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/we.png' }
];

export const listImages = [
    {
        id:1,
        images: [
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[9].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex position={"relative"} className='col2-mon' width={"100%"} padding={"3rem"} justifyContent={'end'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={assets1[0].imgUrl} 
                        width='100' 
                        height='100' 
                        alt='icon'/>
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        fontFamily={'Aileron-regular'}
                        float='right'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}>
                        Gym interior
                    </Text>
                </Flex>
                <Flex className='col3-mon' backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[1].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>, 
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[0].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"100%"} padding={"3rem"} justifyContent={'end'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={assets1[1].imgUrl} 
                        width='100' 
                        height='100' 
                        alt='icon'/>
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        Coworking
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[2].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[1].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"100%"} padding={"3rem"} justifyContent={'end'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={assets1[2].imgUrl} 
                        width='100' 
                        height='100' 
                        alt='icon'/>
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        Gym exterior
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[3].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[2].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"100%"} padding={"3rem"} justifyContent={'end'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={assets1[3].imgUrl} 
                        width='100' 
                        height='100' 
                        alt='icon'/>
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        Hamacas
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[4].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[3].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"100%"} padding={"3rem"} justifyContent={'end'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={assets1[4].imgUrl} 
                        width='100' 
                        height='100' 
                        alt='icon'/>
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        Juegos
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[5].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[4].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"100%"} padding={"3rem"} justifyContent={'end'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={assets1[5].imgUrl} 
                        width='100' 
                        height='100' 
                        alt='icon'/>
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        Looby
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[6].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[5].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"100%"} padding={"3rem"} justifyContent={'end'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={assets1[6].imgUrl} 
                        width='100' 
                        height='100' 
                        alt='icon'/>
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        Pergolado
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[7].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[6].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"100%"} padding={"3rem"} justifyContent={'end'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={assets1[7].imgUrl} 
                        width='100' 
                        height='100' 
                        alt='icon'/>
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        Sky bar
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[8].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[7].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"100%"} padding={"3rem"} justifyContent={'end'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={assets1[8].imgUrl} 
                        width='100' 
                        height='100' 
                        alt='icon'/>
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        Social Room
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[9].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[8].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"100%"} padding={"3rem"} justifyContent={'end'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={assets1[9].imgUrl} 
                        width='100' 
                        height='100' 
                        alt='icon'/>
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        Terraza
                    </Text>
                </Flex>
                <Flex className='col3-mon' backgroundImage={`linear-gradient(#5D7261a3, #5D7261a3), url('${assets1[0].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>
        ]
    },
    {
        id:2,
        images: [
            <Flex width={"100%"} padding={"0rem"} my={10} justifyContent={"center"}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={assets2[0].imgUrl}
                            width='90%' 
                            height='auto' 
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} fontFamily={'Aileron'}>
                        <Text color='#5D7261' fontFamily={'Aileron-regular'} lineHeight={'48px'} fontSize={'40px'} fontWeight={600} mb={4}>
                            Modelo Serena
                        </Text>
                       
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={0}>
                            <CircleIcon boxSize={2}/>Desde 58.01 m<sup>2</sup> hasta 62.14 m<sup>2</sup>
                        </Text>

                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 recámaras
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 baños 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Sala-comedor 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Cocina
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Área de lavado
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Vista norte o sur
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>1 cajón de estacionamiento
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} my={10} justifyContent={"center"}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={assets2[1].imgUrl}
                            width='90%' 
                            height='auto' 
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} fontFamily={'Aileron'}>
                        <Text color='#5D7261' fontFamily={'Aileron-regular'} lineHeight={'48px'} fontSize={'40px'} fontWeight={600} mb={4}>
                            Modelo Essencia
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={0}>
                            <CircleIcon boxSize={2}/>Desde 68.81 m<sup>2</sup> hasta 73.17 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 recámaras
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 baños 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Sala-comedor 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Cocina
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Área de lavado
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Terraza techada
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Vista norte o sur
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
           <Flex width={"100%"} padding={"0rem"} my={10} justifyContent={"center"}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={assets2[2].imgUrl}
                            width='90%' 
                            height='auto' 
                            alt='icon'/>
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} fontFamily={'Aileron'}>
                        <Text color='#5D7261' fontFamily={'Aileron-regular'} lineHeight={'48px'} fontSize={'40px'} fontWeight={600} mb={4}>
                            Modelo Mila
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Desde 74.68 m<sup>2</sup> hasta 75.67 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={0}>
                            <CircleIcon boxSize={2}/>1 recámara + flex
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 baños 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Sala-comedor 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Cocina
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Área de lavado
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Terraza techada
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Vista oriente
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>1 cajón de estacionamiento
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} my={10} justifyContent={"center"}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={assets2[3].imgUrl}
                            width='90%' 
                            height='auto' 
                            alt='icon'/>
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} fontFamily={'Aileron'}>
                        <Text color='#5D7261' fontFamily={'Aileron-regular'} lineHeight={'48px'} fontSize={'40px'} fontWeight={600} mb={4}>
                            Modelo Pacífica
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={0}>
                            <CircleIcon boxSize={2}/>Desde 77.41 m<sup>2</sup> hasta 82.46 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 recámaras + flex
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 baños 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Sala-comedor 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Cocina
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Área de lavado
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Terraza techada
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Vista norte o sur
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'28.8px'} fontSize={'24px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
        ],
        imagesMobile:[
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image  
                            src={assets2[0].imgUrl}
                            width='70%' 
                            height='auto' 
                            alt='icon'
                            mx='auto'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4} fontFamily={'Aileron'}>
                        <Text textAlign={'center'} fontFamily={'Aileron-regular'} color='#5D7261' fontSize={'30px'} lineHeight={'36px'} fontWeight={600} mb={4}>
                            Modelo Serena
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={0}>
                            <CircleIcon boxSize={2}/>Desde 58.01 m<sup>2</sup> hasta 62.14 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 recámaras
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 baños
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Sala-comedor
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Cocina
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Área de lavado
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Vista norte o sur
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>1 cajón de estacionamiento
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image  
                            src={assets2[1].imgUrl}
                            width='100%' 
                            height='auto' 
                            alt='icon'
                            mx='auto'/>
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4} fontFamily={'Aileron'}>
                        <Text textAlign={'center'} fontFamily={'Aileron-regular'} color='#5D7261' fontSize={'30px'} lineHeight={'36px'} fontWeight={600} mb={4}>
                            Modelo Essencia
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={0}>
                            <CircleIcon boxSize={2}/>Desde 68.81 m<sup>2</sup> hasta 73.17 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 recámaras
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 baños
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Sala-comedor 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Cocina
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Área de lavado
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Terraza techada
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Vista norte o sur
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image  
                            src={assets2[2].imgUrl}
                            width='100%' 
                            height='auto' 
                            alt='icon'
                            mx='auto'/>
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4} fontFamily={'Aileron'}>
                        <Text textAlign={'center'} fontFamily={'Aileron-regular'} color='#5D7261' fontSize={'30px'} lineHeight={'36px'} fontWeight={600} mb={4}>
                            Modelo Mila
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={0}>
                            <CircleIcon boxSize={2}/>Desde 74.68 m<sup>2</sup> hasta 75.67 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>1 recámara + flex
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 baños
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Sala-comedor 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Cocina
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Área de lavado
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Terraza techada
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Vista oriente
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>1 cajón de estacionamiento
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"1rem"}  my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image  
                            src={assets2[3].imgUrl}
                            width='100%' 
                            height='auto' 
                            alt='icon'
                            mx='auto'/>
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4} fontFamily={'Aileron'}> 
                        <Text textAlign={'center'} fontFamily={'Aileron-regular'} color='#5D7261' fontSize={'30px'} lineHeight={'36px'} fontWeight={600} mb={4}>
                            Modelo Pacífica
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={0}>
                            <CircleIcon boxSize={2}/>Desde 77.41 m<sup>2</sup> hasta 82.46 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 recámaras + flex
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 baños
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Sala-comedor 
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Cocina
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Área de lavado
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Terraza techada
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>Vista norte o sur
                        </Text>
                        <Text color='brand.500' fontFamily={'Aileron-regular'} lineHeight={'24px'} fontSize={'20px'} fontWeight={400} mb={1}>
                            <CircleIcon boxSize={2}/>2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>
        ]
    },
    {
        id:3,
        images:[
            <Flex  placeItems={'end'} w='100%' h={'100%'} mx='auto'>
                <Image src={assets3[0].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon'/>
                <Text
                    width={'287px'}
                    height={'72px'}
                    display={'flex'}
                    position={'absolute'}
                    py={6}
                    px={{base: 0, md: 12}}
                    mb={0}
                    align={'center'}
                    color={'brand.500'}
                    fontWeight={400}
                    backgroundColor={'white'}
                    textTransform={'uppercase'}
                >
                    Ventura vertical district 
                </Text>
            </Flex>,
            <Flex placeItems={'end'} w='100%' h={'100%'} mx='auto'>
                <Image src={assets3[1].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon'/>
                <Text
                    width={'287px'}
                    height={'72px'}
                    display={'flex'}
                    position={'absolute'}
                    py={6}
                    px={{base: 0, md: 12}}
                    mb={0}
                    align={'center'}
                    color={'brand.500'}
                    fontWeight={400}
                    backgroundColor={'white'}
                    textTransform={'uppercase'}
                >
                    Soaré apartaments 
                </Text>
            </Flex>,
            <Flex placeItems={'end'} w='100%' h={'100%'} mx='auto'>
                <Image src={assets3[2].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon'/>
                <Text
                    width={'287px'}
                    height={'72px'}
                    display={'flex'}
                    position={'absolute'}
                    py={6}
                    px={{base: 0, md: 12}}
                    mb={0}
                    color={'brand.500'}
                    fontWeight={400}
                    align={'center'}
                    backgroundColor={'white'}
                    textTransform={'uppercase'}
                >
                    Classiqa chapalita
                </Text>
            </Flex>,
            <Flex placeItems={'end'} w='100%' h={'100%'} mx='auto'>
                <Image src={assets3[3].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon'/> 
                <Text
                    width={'287px'}
                    height={'72px'}
                    display={'flex'}
                    position={'absolute'}
                    py={6}
                    px={{base: 0, md: 12}}
                    mb={0}
                    color={'brand.500'}
                    fontWeight={400}
                    align={'center'}
                    backgroundColor={'white'}
                    textTransform={'uppercase'}
                >
                    We solares
                </Text>
            </Flex>
        ]
    }
];
