import React from 'react'
import {
    Button,
    Image,
    useDisclosure
} from '@chakra-ui/react';
import ModalCard from './ModalCard';

const ButtonPage = ({ title, bgColor, hover, txtSize, facebook, fontColor, idFormHs, thankYouPage, isImage = false, srcImg, ...props }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {isImage ?
                <Image
                    src={srcImg}
                    w={{ base: "50px", md: "60px" }}
                    cursor={'pointer'}
                    onClick={() => onOpen()}
                    _hover={{ transform: 'scale(1.1)' }}
                />
                :
                <Button
                    fontSize={'sm'}
                    fontWeight={txtSize}
                    variant={'link'}
                    bg={bgColor}
                    color={fontColor ? fontColor : 'white'}
                    padding={3}
                    paddingLeft={6}
                    paddingRight={6}
                    as='a'
                    borderRadius='none'
                    //display={{ base: 'none', md: 'flex' }}
                    _hover={{
                        bg: hover,
                        cursor: "pointer"
                    }}
                    {...props}
                >
                    {title}
                </Button>
            }
            {isOpen ?
                <ModalCard
                    isOpen={isOpen}
                    onClose={onClose}
                    title={title}
                    idForm={idFormHs}
                    thankyoupage={thankYouPage}
                    facebook={facebook}
                />
                :
                null
            }
        </>
    )
}

export default ButtonPage