import React from 'react'
import { Flex, Stack, Text, Link, useMediaQuery, useTheme, Image } from '@chakra-ui/react';
import "../styles/footer.css";

const Footer = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/tya-footer.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/assets/icons/icon-guia-logo-r-1.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/fb.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/ig.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/wa_f.svg' }
    ];

    return (
        <Flex my={"3rem"} mx={isGreaterThanMd?"3rem":"1.2rem"} flexDirection={"column"} id='Footer'>
            <Flex display={isGreaterThanMd?'flex':'none'}>
                <Flex className='footer-logos' width={isGreaterThanMd?"33%":"100%"} height={"fit-content"}>
                    <Stack direction='row'>
                    
                        <Text fontSize={'xs'} fontWeight='300' textAlign={'center'} pr={4}>
                            Una creación de:
                            <Image style={{ margin: "auto" }} src={assets[0].imgUrl} width='170' height='35' alt='Logo TyA'/>
                        </Text>
                        <Text fontSize={'xs'} fontWeight='300' textAlign={'center'} pr={4}>
                            Comercializado por:
                            <img style={{ height: "auto" }} src={assets[1].imgUrl} width='160' height='75' alt='Logo Guia'/>
                        </Text>
                    </Stack>
                </Flex>
                <Flex className='footer-copyright-top' width={"33%"} justifyContent='center'>
                    <Text fontSize='sm' color='#545454' fontWeight={400}>
                        Copyright &#169; 2023 &#169; All Rights Reserved - Powered by Grupo Guia
                        <br />
                        <Link href='https://grupoguia.mx/Aviso-de-privacidad' isExternal>Aviso de privacidad</Link>
                    </Text>
                </Flex>
                <Flex className='footer-iconos'width={isGreaterThanMd?"33%":"100%"}  height={"fit-content"} justifyContent={"end"}>
                    <Stack justifyContent={"end"} direction='row'>
                        <Link href='https://www.facebook.com/profile.php?id=100090021504918' isExternal>
                            <img style={{ marginRight: "1rem" }} src={assets[2].imgUrl} width='42' height='42' alt='Facebook'/>
                        </Link>
                        <Link href='https://www.instagram.com/ambienta3070/' isExternal>
                            <img style={{ marginRight: "1rem" }} src={assets[3].imgUrl} width='42' height='42' alt='Facebook'/>
                        </Link>
                        <Link href='https://share.hsforms.com/1Xr6gncJ4QQuj2jLkZPYNNg3j49k' isExternal>
                            <img style={{ marginRight: "1rem" }} src={assets[4].imgUrl} width='42' height='42' alt='Facebook'/>
                        </Link>
                    </Stack>
                </Flex>
            </Flex>
            <Flex display={isGreaterThanMd?'none':'flex'} alignSelf='center'>
                <Stack direction='col' alignItems={'center'}>
                    <Link href='https://www.facebook.com/profile.php?id=100090021504918' isExternal>
                        <img style={{ marginRight: "1rem" }} src={assets[2].imgUrl} width='42 ' height='42' alt='Facebook'/>
                    </Link>
                    <Link href='https://www.instagram.com/ambienta3070/' isExternal>
                        <img style={{ marginRight: "1rem" }} src={assets[3].imgUrl} width='42' height='42' alt='Instagram'/>
                    </Link>
                    <Link href='https://share.hsforms.com/1Xr6gncJ4QQuj2jLkZPYNNg3j49k' isExternal>
                        <img style={{ marginRight: "1rem" }} src={assets[4].imgUrl} width='42' height='42' alt='Wpp'/>
                    </Link>
                </Stack>
            </Flex>
            <Flex display={isGreaterThanMd?'none':'flex'} pt={6} alignSelf='center'>
                <Stack justifyContent={"center"} direction='row' alignItems={'center'}>
                    <Text fontSize={'xs'} fontWeight='300' textAlign={'center'} pr={4}>
                        Una creación de:
                        <Image style={{ margin: "auto" }} src={assets[0].imgUrl} width='170' height='35' alt='Logo TyA'/>
                    </Text>
                    <Text fontSize={'xs'} fontWeight='300' textAlign={'center'} pr={4}>
                        Comercializado por:
                        <img style={{ height: "auto" }} src={assets[1].imgUrl} width='160' height='75' alt='Logo Guia'/>
                    </Text>
                </Stack>
            </Flex>
            <Flex className='footer-copyright-bottom' pt={6} justifyContent={"center"} marginTop={isGreaterThanMd?"2rem":2}>
                <Flex textAlign={"center"} justifyContent={"center"}>
                    <Text fontSize='sm' color='#545454' fontWeight={400}>
                        Copyright &#169; 2023 &#169; All Rights Reserved - Powered by Grupo Guia
                        <br />
                        <Link href='https://grupoguia.mx/Aviso-de-privacidad' isExternal>Aviso de privacidad</Link>
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Footer