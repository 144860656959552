import React from 'react';
import { 
    Flex, 
    Text,
    useMediaQuery, 
    useTheme,
    Grid,
    GridItem,
    Image
} from '@chakra-ui/react';

const Description = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/img_amen.png' },
    ];

    return (
        <Flex paddingTop={isGreaterThanMd?"2rem":'0px'} flexDirection={"column"} paddingBottom={"1rem"} w='100%' id='proyecto'>
            <Grid templateColumns={isGreaterThanMd ? "repeat(5, 1fr)" : "repeat(2, 1fr)"}  px={isGreaterThanMd?24:8} maxW={'5xl'} margin='auto' pb={14} pt={{base: 5, md: 0}}>
                <GridItem colSpan={isGreaterThanMd?3:2} mb={isGreaterThanMd?0:8} alignSelf='center'>
                    <Flex flexDirection={"column"} py={isGreaterThanMd?20:0} px={isGreaterThanMd?10:2} textAlign={isGreaterThanMd?'start':'justified'}>
                        <Flex paddingBottom={isGreaterThanMd ? "2rem" : "1rem"} w={'100%'} >
                            <Text fontFamily={'Aileron-bold'} fontSize={isGreaterThanMd ? '23px': '18px'} fontWeight={700} lineHeight={isGreaterThanMd?'24.15px':'18.9px'} color='#545454' >
                            Ambienta 3070 es un proyecto moderno y relajado que tiene todo lo que necesitas para pasarla increíble todos los días.
                            </Text>
                        </Flex>
                        <Flex>
                            <Text fontFamily={'Aileron-regular'} fontWeight={400} fontSize={isGreaterThanMd ? '16px':'14px'} lineHeight={isGreaterThanMd?'19.2px':'14.4px'} color='#535353'>
                            Departamentos únicos, donde la diversión, el confort y la tranquilidad se fusionan para brindarte las mejores experiencias de vida.
                            <br/>
                            <br/>
                            Aquí encontrarás espacios increíbles para disfrutar la vida como nunca antes: Sky bar, social room, terrazas, y mucho más, para pasar momentos inolvidables en compañía de tus personas favoritas.
                            <br/>
                            <br/>
                            Todo lo que siempre soñaste está en Ambienta 3070. ¡Vívelo!
                            </Text>
                        </Flex>
                    </Flex>
                </GridItem>
                <GridItem colSpan={2} justifyContent='center'>
                    <Flex paddingLeft={isGreaterThanMd?"2rem":0} justifyContent='center' h='100%'>
                        <Image 
                            src={assets[0].imgUrl}
                            width='100%' 
                            height='auto' 
                            alt='amenidades' 
                            style={{objectFit: 'contain', background: 'center'}}
                        />
                    </Flex>
                </GridItem>
            </Grid>
        </Flex>
    );
}
 
export default Description;