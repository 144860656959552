import React from 'react'
import { Flex, Text, useMediaQuery, useTheme,Link,Image  } from '@chakra-ui/react';

const Cercanias = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    const assets = [
        { imgUrl: isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/cercanias.png' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/mobile/mapa-mobile.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/simbologia.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/maps.svg' }
    ];

  return (
    <Flex paddingTop="2rem" marginBottom={"4rem"} justifyContent='center' py={isGreaterThanMd?4:10}>
            <Flex alignItems={"center"} flexDirection={"column"} color={"#545454"}>
                <Flex alignItems={"center"} textAlign={"center"} flexDirection={"column"} px={4}>
                    <Text 
                        fontWeight={"700"} 
                        color={'#5D7261'}
                        textTransform={"uppercase"} 
                        fontSize={isGreaterThanMd ? '50px': '30px'}
                        lineHeight={isGreaterThanMd ? '60px' : '36px'}
                        mb={0} 
                        fontFamily={'Aileron-bold'}
                        textAlign={'center'}
                    
                    >
                        Cercanías
                    </Text>
                    <Text fontFamily={'Aileron-bold'} width={isGreaterThanMd?"65%":"90%"} textAlign={'center'}  fontWeight={700} fontSize={isGreaterThanMd?'30px':'18px'} lineHeight={isGreaterThanMd ? '36px': '21.6px'}>
                    La libertad de hacer lo que más te gusta
                    </Text>
                    <Text fontFamily={'Aileron-regular'} marginTop={"1.5rem"} textAlign={'center'} marginBottom={"1rem"} fontWeight={400} fontSize={isGreaterThanMd?'23px':'18px'} lineHeight={isGreaterThanMd ? '30.47px': '23.85px'}>
                    Un estilo de vida excepcional te espera en Ambienta 3070. Gracias a su ubicación, podrás disfrutar de todos<br/> los servicios a unos pasos de tu depa, como restaurantes, bares, centros comerciales, escuelas, parques, <br/>centros de negocios, ¡y mucho más!                    </Text>
                </Flex>
                <Flex justifyContent={"center"}>
                    <img src={assets[0].imgUrl} style={{ width: isGreaterThanMd ? "85%" : "100%", height: 'auto' }} width="500" height="500" alt='cercanias' onClick={()=>{window.open('https://goo.gl/maps/oo2XgEKUvDgXAhst8', '_blank')}}/>
                </Flex>
                <Flex justifyContent={"center"}>
                    <img src={assets[1].imgUrl} style={{ width: isGreaterThanMd ? "85%" : "90%", height: 'auto' }} width="500" height="500" alt='cercanias' onClick={()=>{window.open('https://goo.gl/maps/oo2XgEKUvDgXAhst8', '_blank')}}/>
                </Flex>
                <Flex flexDirection={isGreaterThanMd ? "row" : "column"} justifyContent={"center"} alignItems={"center"} mt={4}>
                <Image m={isGreaterThanMd ? "0px 1rem" : "1rem"} src={assets[2].imgUrl} width={isGreaterThanMd ? "52px" : "40px"} height={isGreaterThanMd ? "47px" : "36px"} alt="descripción" />
                <Link  color={"#0882C6"} fontSize={"16px"} href='Av. Hidalgo 352, Cabecera Municipal, Zapopan, Jalisco.'>
                    {isGreaterThanMd ? 
                    <span>Av. Hidalgo 352, Cabecera Municipal, Zapopan, Jalisco.</span> : <span>Av. Hidalgo 352, Cabecera<br />Municipal, Zapopan, Jalisco.</span>}
                </Link>
                </Flex>
                
            </Flex>
            
        </Flex>
  )
}

export default Cercanias