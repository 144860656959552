import React from 'react';
import { 
    Flex, 
    Text,
    Grid,
    GridItem,
    Image
} from '@chakra-ui/react';

const AmenidadesMb = () => {
    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/img_amen.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/torre.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/depto.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/recamara.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/mts.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/ameni.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/finan.svg' }
    ];

    return ( 
        <Grid id='iconos' templateColumns={"repeat(2, 1fr)"}  px={8} w={'100%'} margin='auto' pb={16} color='#5B5B5F'>
            <GridItem colSpan={2} justifyItems=''>
                <Grid templateColumns={"repeat(2, 1fr)"}  px={4} w={'100%'} margin='auto' pb={16} gap={8} justifyItems={'center'}>
                    <GridItem colSpan={1}>
                        <Flex alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[1].imgUrl} boxSize={'60px'}  alt='torre'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'start'}>
                                1 torre
                            </Text>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <Flex alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[2].imgUrl} boxSize={'60px'} alt='departamentos'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'center'}>
                                    77 departamentos
                            </Text>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <Flex alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[3].imgUrl} boxSize={'60px'} alt='recamaras'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'center'}>
                                De 1 y 2 recámaras <br />con opción a flex
                            </Text>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={1} display={'block'}>
                        <Flex  alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[4].imgUrl} boxSize={'60px'} alt='metros'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'center'}>
                            Desde 58.07 m<sup>2</sup> <br/> hasta 81.14  m<sup>2</sup>
                            </Text>
                        </Flex>
                    </GridItem>
                </Grid>
            </GridItem>
            <GridItem  colSpan={2} >
                <Grid id='justify'  templateColumns={"repeat(2, 1fr)"}  px={4} w={'100%'} margin='auto' pb={16} gap={8} justifyItems={'center'} >
                    <GridItem colSpan={1}>
                        <Flex alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[5].imgUrl} boxSize={'60px'} alt='amenidades'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'center'}>
                                    Amenidades <br/>para sentirte libre
                            </Text>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <Flex alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[6].imgUrl} boxSize={'60px'} alt='financiamiento'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'center'}>
                            Financiamiento <br/> directo
                            </Text>
                        </Flex>
                    </GridItem>
                </Grid>
            </GridItem>
        </Grid>
    );
}
 
export default AmenidadesMb;