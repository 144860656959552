import React, { useEffect } from 'react';
import { 
    Flex, 
    Text,
    Grid,
    GridItem,
    Image
} from '@chakra-ui/react';
import './animation.css';

const Amenidades = () => {

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/img_amen.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/torre.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/depto.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/recamara.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/mts.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/ameni.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/finan.svg' }
    ];

    useEffect(() => {
        const observerL = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show-amenidades');
          } else {
            entry.target.classList.remove('show-amenidades');
          }
        })
        })
          
        const hiddenElementsL = document.querySelectorAll('.hidden-left-amenidades');
        hiddenElementsL.forEach((el) => observerL.observe(el));
    }, []);

    return ( 
        <div className='sections-amenidades'>
            <div className='hidden-left-amenidades'>
                <Grid id='iconos' templateColumns={"repeat(5, 1fr)"} px={24} w={'80%'} margin='auto' pb={16} color='#5B5B5F'>
                    <GridItem colSpan={5} justifyItems=''>
                        <Grid templateColumns={"repeat(3, 1fr)"}  px={10} w={'95%'} margin='auto' pb={16} gap={8} justifyItems={'left'}>
                            <GridItem colSpan={1}>
                                <Flex alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                    <Image src={assets[1].imgUrl} boxSize={'60px'}  alt='torre'/>
                                    <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'start'}>
                                        1 torre
                                    </Text>
                                </Flex>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <Flex alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                    <Image src={assets[2].imgUrl} boxSize={'60px'} alt='departamentos'/>
                                    <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'start'}>
                                        77 departamentos
                                    </Text>
                                </Flex>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <Flex alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                    <Image src={assets[3].imgUrl} boxSize={'60px'} alt='recamaras'/>
                                    <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'start'}>
                                    De 1 y 2 recámaras <br />con opción a flex
                                    </Text>
                                </Flex>
                            </GridItem>
                        </Grid>
                    </GridItem>
                    <GridItem  colSpan={5} >
                        <Grid id='justify'  templateColumns={"repeat(3, 1fr)"}  px={10} w={'95%'} margin='auto' pb={16} gap={8} justifyItems={'left'} >
                            <GridItem colSpan={1} display={'block'}>
                                <Flex alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                    <Image  src={assets[4].imgUrl} boxSize={'60px'}  alt='metros'/>
                                    <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'start'}>
                                    Desde 58.07 m<sup>2</sup> <br/> hasta 81.14 m<sup>2</sup>
                                    </Text>
                                </Flex>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <Flex alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                    <Image src={assets[5].imgUrl} boxSize={'60px'} alt='amenidades'/>
                                    <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'start'}>
                                        Amenidades <br/>para sentirte libre
                                    </Text>
                                </Flex>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <Flex alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                    <Image src={assets[6].imgUrl} boxSize={'60px'} alt='financiamiento'/>
                                    <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'Aileron-regular'} fontWeight={400} color='brand.500' textAlign={'start'}>
                                    Financiamiento <br/> directo
                                    </Text>
                                </Flex>
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </div>
        </div>
    );
}
 
export default Amenidades;
