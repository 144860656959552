import React from 'react'
import {
    Tab,
    Tabs,
    TabPanels,
    TabPanel,
    TabList,
    Divider,
    Flex,
    Text
} from '@chakra-ui/react';
import { list } from '../resource';

const TableMobile = () => {
  return (
    <Tabs variant='enclosed' flexDirection={"column"} alignItems={"center"}>
            <TabList width={"max-content"} flexDirection={"column"} margin={"auto"} fontFamily={'Aileron-regular'}>
                <Tab fontSize={"22px"} _selected={{ bg: "#5D7261", color: "#fff", fontWeight: "600" }}>Contado</Tab>
                <Tab fontSize={"22px"} _selected={{ bg: "#5D7261", color: "#fff", fontWeight: "600" }}>Contado comercial</Tab>
                <Tab fontSize={"22px"} _selected={{ bg: "#5D7261", color: "#fff", fontWeight: "600" }}>Plan guía</Tab>
                <Tab fontSize={"22px"} _selected={{ bg: "#5D7261", color: "#fff", fontWeight: "600" }}>Financiamiento</Tab>
                <Tab fontSize={"22px"} borderColor={"#FFF"} _selected={{ bg: "#5D7261", color: "#fff", fontWeight: "600" }}>Plan Hipotecario</Tab>
            </TabList>
            <TabPanels mt={10}>
                {list.map((item, idx) => (
                    <TabPanel key={idx}>
                        <Flex flexDirection={"column"}>
                            <Flex flexDirection={"column"}>
                                <Text fontSize='16px' textTransform={"capitalize"} fontFamily={'Aileron-regular'} color={"#5D7261"} fontWeight={700}>{`Descuento ${item.num}`}</Text>
                                <Text fontSize='16px' fontWeight={700} fontFamily={'Aileron-regular'} mb={5}>{item.pay}</Text>
                                <Divider />
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"column"}>
                            {item.tab.map((element, idx1) => (
                                <Flex key={idx1} flexDirection={"column"} lineHeight={2}>
                                    <Text fontSize='16px' textTransform={"inherit"} fontFamily={'Aileron-regular'} fontWeight={700}>{element.item1}</Text>
                                    <Text fontSize='16px' textTransform={"inherit"} fontFamily={'Aileron-regular'} fontWeight={700}>{element.item2}</Text>
                                    <Divider />
                                </Flex>
                            ))}
                        </Flex>
                        <br/>
                        <Flex flexDirection={'column'}>
                                <Text textAlign={'center'} fontSize={'9px'} fontFamily={'Aileron-regular'}  fontWeight={400} >
                                *Politica Comercial sujeta a cambio sin previo aviso y sujeto a disponibilidad.
                                </Text>
                        </Flex>
                    </TabPanel>            
                ))}
            </TabPanels>
        </Tabs>
  )
}

export default TableMobile