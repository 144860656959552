import React, { useState, useEffect } from 'react';
import { 
    Flex, 
    Text,
    useMediaQuery,
    useTheme,
    Button,
    useDisclosure,
    Stack,
    Box,
    Image
} from '@chakra-ui/react';

import "../styles/styled.css";
import "../styles/home.css";
import Nav from './Nav';
import ModalCard from './ModalCard';

const Presentacion = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [id, setId] = useState(0);

    const assets = [
        { imgUrl: isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/img1.svg' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/mobile/img1-mobile-bg.png' },
        { imgUrl: isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/img2.svg' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/mobile/img2-mobile-bg.png' },
        { imgUrl: isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/img3.svg' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/mobile/img3-mobile-bg.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/circle.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/circle-white.png' }
    ];

    const [img, setImg] = useState("url('"+assets[0].imgUrl+"')");
    const [text1, setText1] = useState('Tu depa a');
    const [text2, setText2] = useState('500 m de la minerva');
    const [text3, setText3] = useState('4 modelos diferentes de 1 y 2 recámaras con opción a flex, desde  $4.8 MD');
    const [nums, setNums] = useState(0);
    const [change, setChange] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const infoSlider = [
        {
            title_kenoky: '11%* De Rendimiento',
            title_alieron: '',
            sub_title_alieron: 'Anual Por Rentas ESTIMADO',
            description: 'Obtén el máximo retorno de tu inversión por medio de rentas de corto plazo'
        },
        {
            title_kenoky: 'TU DEPA EN LA ZONA',
            title_alieron: '',
            sub_title_alieron: 'CON MÁS DEMANDA PARA RENTAS',
            description: 'Preventa: A 500m de La Minerva, restaurantes, bares, centros comerciales y más.'
        },
        {
            title_kenoky: 'AMENIDADES',
            title_alieron: '',
            sub_title_alieron: 'QUE Deleitarán A TUS HUÉSPEDES',
            description: 'Y que te ayudarán a tener una alta tasa de ocupación: Sky Bar, Coworking, Gym y más.'
        }
    ]

    const imagesSlider = [
        {
            imgDesktop: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/slider/slide_1.png',
            imgMobile: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/slider/slide_mobile_1.png',
            text: 'A solo 500m de la Minerva',
        },
        {
            imgDesktop: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/slider/slide_2.png',
            imgMobile: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/slider/slide_mobile_2.png',
            text: '',
        },
        {
            imgDesktop: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/slider/slide_3.png',
            imgMobile: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/slider/slide_mobile_3.png',
            text: '',
        },
    ]
    
    const changeImage = (num) => {
        switch (num) {
            case 0:
            case 1:
                setId(0);
                // setImg("url('"+assets[0].imgUrl+"')");
                // setText1("Tu depa a");
                // setText2("500 m de la minerva");
                // setText3(" 4 modelos diferentes de 1 y 2 recámaras con opción a flex, desde  $4.8 MDP")
                break;
            case 2:
                setId(1);
                // setImg("url('"+assets[1].imgUrl+"')");
                // setText1("Tu depa rentado");
                // setText2("desde el primer dia ");
                // setText3(" Depas con terraza desde $4.8 MDP");
                break;
            case 3:
                setId(2);
                // setImg("url('"+assets[2].imgUrl+"')");
                // setText1("Amenidades únicas");
                // setText2("para disfrutar la vida");
                // setText3("Sky Bar, Terraza, Hammock Garden y Gimnasio.");
                break;
            default:
                break;
        }
    }

    const changeImg = () => {
        setTimeout(() => {
            var sum = nums+1;
            setNums(sum);
            setChange(!change);
            if (nums === 3) {
                setNums(1);
            }
            changeImage(nums);
        }, 8000);
    }

    useEffect(() => {
        changeImg();
    }, [change]);

    useEffect(() => {
        changeImg();
    }, []);

    return (
        <>
            <Flex display={"block"}>
                <Nav />
            </Flex>
            {/* <Flex id='presentacion' padding={"1rem"}>
                <Flex flexDirection={isGreaterThanMd ? "row" : "column"} color={"#FFF"} background={"#5D7261"} width={"100%"} height={isGreaterThanMd ? "100%" : "100vh"} borderRadius={"10px"}>
                    <Flex paddingLeft={'1rem'} width={isGreaterThanMd ? "60%" : "100%"} flexDirection={"column"} justifyContent={"center"} alignItems={isGreaterThanMd ? "left" : "center"}>
                        <Text lineHeight={isGreaterThanMd ? '84px' :'42px'} textTransform={'uppercase'} fontFamily={'Aileron-regular'} fontSize={isGreaterThanMd ? "70px" : "30px"} fontWeight={700}>{text1}</Text>
                        <Text textAlign={isGreaterThanMd ? 'initial' : "center"} lineHeight={isGreaterThanMd ? '84px' :'42px'} textTransform={'uppercase'} fontFamily={'Kenoky-Light'} fontSize={isGreaterThanMd ? "70px" : "28px"} fontWeight={300}>{text2}</Text>
                        <br/>
                        <Text textAlign={isGreaterThanMd ? 'initial' : "center"} lineHeight={isGreaterThanMd ? '36px' :'20px'} fontFamily={'Aileron-regular'} fontSize={isGreaterThanMd ? "30px" : "18px"} fontWeight={400}>{text3}</Text>
                        <br/>
                        <Button onClick={onOpen} fontFamily={'Aileron-bold'} fontSize={'16px'} fontWeight={700} width={"217px"} borderRadius={"0px"} bg='#fff' color="#5D7261">
                            Quiero ser contactado
                        </Button>
                        <br/>
                    </Flex>
                    <Flex width={isGreaterThanMd ? "50%" : "100%"} padding={"1rem"} justifyContent={isGreaterThanMd ? "center" : "left"}>
                        <Flex backgroundSize={'cover'} backgroundPosition={"center"} backgroundRepeat={"no-repeat"} backgroundImage={img} width={"430px"} height={isGreaterThanMd ? "770px" : "60vh"}></Flex>
                    </Flex>
                    <Flex
                        id='sc-points'
                        justifyContent="end"
                        display={isGreaterThanMd?'flex':'none'}
                        position={'relative'}
                        alignItems="center"
                        paddingLeft={"5rem"}
                        bottom="8"
                        >
                        <Flex position={'absolute'} flexDirection="column" paddingRight={10} zIndex={1}>
                            <Flex mb={2} justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(1)}>
                                <img src={id === 1 ? assets[3].imgUrl : assets[4].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon'/>
                            </Flex>
                            <Flex mb={2} justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(2)}>
                                <img src={id === 2 ? assets[3].imgUrl : assets[4].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon'/>
                            </Flex>
                            <Flex justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(3)}>
                                <img src={id === 3 ? assets[3].imgUrl : assets[4].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon'/>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex> */}
            <Flex padding={"1rem"} justifyContent={"center"} direction={{ base: "column", md: "row" }}>
                <Flex width={isGreaterThanMd ? "50%" : "100%"}>
                    <Stack pl={{base: 0, md: 20}}>
                        <Stack>
                            <Text
                                fontSize={{ base: "40px", md: '50px', lg: "70px" }}
                                fontWeight={400}
                                fontFamily={'Kenoky-Light'}
                                color='#5D7261'
                            >
                                {infoSlider[id].title_kenoky} 
                                <Text
                                fontSize={{  base: "40px", md: '50px', lg: "70px" }}
                                fontWeight={300}
                                textTransform={'uppercase'}
                                fontFamily={'Aileron-regular'}
                                color='#5D7261'
                                display={'inline-flex'}
                                ml={2}
                                >
                                    {infoSlider[id].title_alieron}
                                </Text>
                            </Text>
                            <Text
                                fontSize={{base: '20px', md: '30px', lg: '40px'}}
                                fontWeight={300}
                                textTransform={'uppercase'}
                                fontFamily={'Aileron-regular'}
                                color='#5D7261'
                            >
                                {infoSlider[id].sub_title_alieron}
                            </Text>
                        </Stack>
                        <Stack my={8}>
                            <Text
                                fontSize={{ base: "18px", md: '20px', lg: '30px' }}
                                fontWeight={400}
                                fontFamily={'Aileron-regular'}
                                color='#5D7261'
                            >
                                {infoSlider[id].description}
                            </Text>
                        </Stack>
                        <Stack>
                            <Button onClick={onOpen} fontFamily={'Aileron-bold'} fontSize={'16px'} fontWeight={700} width={"217px"} borderRadius={"0px"} bg='#E5B843' color="#5D7261">
                                Quiero ser contactado
                            </Button>
                        </Stack>
                    </Stack>
                </Flex>
                <Flex width={isGreaterThanMd ? "50%" : "100%"}>
                    <Stack w={'100%'} px={{base: 0, md: 20}} pt={{base: 20, md: 40}}>
                        <Text
                            pl={{base: 0, md: 6, lg: 0, xl: 4, '2xl': '14%'}}
                            fontWeight={400}
                            fontSize={{base: 'xs', md: 'sm', lg: 'md', xl: 'md', '2xl': 'md'}}
                        >
                            {imagesSlider[id].text}
                        </Text>
                        <Flex placeContent={'end'}>
                            <Box height={'30vh'} bg='#859588' minH={{base: '30vh', md: '40vh'}} maxH='40vh' w='auto' maxW={'40vw'} minW={{base: '90%', md: '35vw'}} position={'absolute'} zIndex={-1}>
                            </Box>
                            <Box>
                            <Image 
                                src={isGreaterThanMd? imagesSlider[id].imgDesktop : imagesSlider[id].imgMobile} 
                                top={id === 0 ? '-20%' : id === 1 ? '-22%' : '-11%'}
                                position={'relative'}
                                minH={'45vh'}
                                minW={{base: '95vw', md: '40vw'}}
                                right={id === 1 ? '-11%': '0'}
                            />
                            <Flex
                                id='sc-points'
                                justifyContent="center"
                                display={isGreaterThanMd?'flex':'none'}
                                position={'relative'}
                                alignItems="center"
                                paddingLeft={"5rem"}
                                bottom={id === 0 ? '23%' : id === 1 ? '25%' : '14%'}
                            >
                                <Flex position={'absolute'} flexDirection="row" paddingRight={10} zIndex={1}>
                                    <Flex justifyContent={"center"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(1)}>
                                        <img src={id === 0 ? assets[3].imgUrl : assets[4].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon'/>
                                    </Flex>
                                    <Flex justifyContent={"center"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(2)}>
                                        <img src={id === 1 ? assets[3].imgUrl : assets[4].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon'/>
                                    </Flex>
                                    <Flex justifyContent={"center"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(3)}>
                                        <img src={id === 2 ? assets[3].imgUrl : assets[4].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon'/>
                                    </Flex>
                                </Flex>
                            </Flex>
                            </Box>
                        </Flex>
                    </Stack>
                </Flex>
            </Flex>
            {isOpen ?
                <ModalCard 
                    isOpen={isOpen} 
                    onClose={onClose}
                    title={"Quiero ser contactado"}
                    //idForm={"22b54777-673f-44b6-bfd8-043fee1a6931"}
                    idForm={"fcc8a6ec-50ca-4c31-a20c-fd689ec217dd"}
                    /> : null
            }
        </>
    );
}
 
export default Presentacion;