import React, {useState} from 'react'
import { Flex, Text, Grid, GridItem, useMediaQuery, useTheme, IconButton } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';
import "../styles/styled.css";
import "../styles/descripcion.css";
import ArrowLeftIcon from "../assets/svgs/ArrowLeftSVG";
import ArrowRightIcon from "../assets/svgs/ArrowRightSVG";

const Desarrollador = () => {
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [activeIndex, setActiveIndex] = useState(0);
  
  const slidePrev = () => {
    activeIndex===0?(
        setActiveIndex(listImages[1].images.length-1)):
      setActiveIndex(activeIndex - 1);
  };

  const slideNext = () => {
    activeIndex===listImages[1].images.length-1?(
      setActiveIndex(0))
        : setActiveIndex(activeIndex + 1)
  };

  const assets = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/tya.svg' }
  ];

  const RenderTYA = () => {
    return (
      <GridItem alignSelf="center" height={"-webkit-fill-available"}>
        <Flex width={"100%"} mb={isGreaterThanMd ? "20%" : "10%"}>
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={isGreaterThanMd ? "initial" : "center"}
            color={"#545454"}
          >
            <img
              src={assets[0].imgUrl}
              width="150"
              height="150"
              alt="descripcion"
            />
            <br/>
            {isGreaterThanMd ?
              <Text
                fontFamily={'Aileron-bold'}
                fontWeight={700}
                fontSize={'22px'}
                textAlign={'left'}
                lineHeight={'26.4px'}
              >
                Tierra y Armonía ha desarrollado un sinnúmero de proyectos en los cuales ofrece calidad de vida, vanguardia e innovación inmobiliaria.

              </Text>
            :
              <Text
                fontFamily={'Aileron-regular'}
                fontWeight={700}
                fontSize={'22px'}
                textAlign={'justify' }
                lineHeight={'21.6px'}
                m={"1rem 0"}
              >
                Tierra y Armonía ha desarrollado un sinnúmero de proyectos en los cuales ofrece calidad de vida, vanguardia e innovación inmobiliaria.
              </Text>
            }
            <br/>
            <Text
              fontSize={"18px"}
              fontWeight={400}
              fontFamily={'Aileron-regular'}
              textAlign={{ base: "justified", lg: "left" }}
              lineHeight={"1.2"}
            >
                Somos la empresa inmobiliaria más importante del Occidente del país. Contamos con más de 40 años de experiencia, ofreciendo creaciones inmobiliarias destinadas a brindar una extraordinaria calidad de vida, generosidad urbana, plusvalía y belleza. A la fecha contamos con más de 30,000 unidades erigidas con una construcción de la más alta calidad.
            </Text>
          </Flex>
        </Flex>
      </GridItem>
    )
  }

  return (
    <Flex
      marginTop={"8rem"}
      id="tya"
      justifyContent={"center"}
    >
      <Grid
        className="section-grid-construvida"
        templateColumns={isGreaterThanLg ? "1fr 50%" : "100%"}
        px={isGreaterThanLg ? 40 : 8}
        width={"100%"}
        pb={5}
        gap={{ base: "2rem", lg: "4rem" }}
      >
        {isGreaterThanMd ? 
          <RenderTYA /> : null
        }
        <GridItem
          mb={isGreaterThanLg ? 0 : 8}
          mt={isGreaterThanLg ? 0 : 8}
          alignSelf="center"
          maxWidth={isGreaterThanLg ? "100%" : "90%"}
        >
          <Flex
            className="section-construvida-carousel"
            flexDirection={"row"}
            width={"100%"}
            padding={isGreaterThanLg ? "0px 6rem" : "0px 0rem"}
            alignItems="center"
            placeContent={"center"}
            position={"relative"}
          >
          
            <IconButton
              icon={<ArrowLeftIcon color="black" />}
              rounded="full"
              border="0"
              shadow="md"
              transitionDuration=".5s"
              _hover={{ shadow: "lg" }}
              //disabled={page <= 1}
              onClick={slidePrev}
              position="relative"
              right={{ base: "-4", md: -5 }}
              bg="white"
              zIndex="2"
            />
            <AliceCarousel
              mouseTracking
              animationDuration={500}
              disableButtonsControls
              activeIndex={activeIndex}
              items={listImages[2].images}
              responsive={true}
              controlsStrategy="responsive"
              animationType="fadeout"
              autoPlayInterval={5000}
              infinite={true}
              keyboardNavigation={true}
              disableDotsControls
              autoHeight={false}
              maxHeight="200px"
            />
            <IconButton
              icon={<ArrowRightIcon color="black" />}
              rounded="full"
              border="0"
              colorScheme="brand"
              shadow="md"
              transitionDuration=".3s"
              _hover={{ shadow: "lg" }}
              onClick={slideNext}
              position="relative"
              left={{ base: "-4", md: -5 }}
              bg="white"
              zIndex="2"
            />
          </Flex>
        </GridItem>
        {!isGreaterThanMd ? 
          <RenderTYA /> : null
        }
      </Grid>
    </Flex>
  )
}

export default Desarrollador