import React, { useState, useEffect } from 'react'
import {
    Flex,
    useMediaQuery,
    useTheme,
    Box,
    Text,
    Button,
    Link,
    useDisclosure
} from '@chakra-ui/react';
import '../styles/parallax.css';
import ModalCard from './ModalCard';

const CTA = ({ ctaNum, text1, text2, text3, ...props }) => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [screenSize, getDimension] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/cta1.png', imgUrlMb: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/mobile/cta1-mobile.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/cta2.png', imgUrlMb: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/mobile/cta2-mobile.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/cta3.png', imgUrlMb: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/mobile/cta3-mobile.png' }
    ];

    const setDimensions = () => {
        getDimension({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", setDimensions);

        return () => {
            window.removeEventListener("resize", setDimensions);
        };
    }, [screenSize]);

    return (
        <Box id='cta' {...props} w='full' paddingInline="0" position="relative" >
            <Flex id='inicio'
                h={"70vh"}
                className='fondo-ambienta'
                backgroundImage={{ base: assets[ctaNum].imgUrlMb, md: assets[ctaNum].imgUrl }}
            >
                <Flex
                    height="70vh"
                    w="100%"
                    position={"absolute"}
                    backgroundColor={"#5D72618e"}>
                </Flex>
                <Flex zIndex={1} flexDirection={"column"} color={"#FFFFFF"} textAlign={"center"}
                    alignItems={"center"} width={"100%"}>
                    <Text fontSize={isGreaterThanMd ? '7xl' : '3xl'} color={'white'} fontFamily={'Aileron-regular'} fontWeight={"400"} letterSpacing='wider' textTransform={"uppercase"}>
                        {text1}
                    </Text>
                    <Text fontSize={isGreaterThanMd ? '7xl' : '3xl'} color={'white'} fontFamily={'Aileron-regular'} fontWeight={"700"} lineHeight={'60px'} letterSpacing='wider' textTransform={"uppercase"}>
                        {text2}
                    </Text>
                    <Text fontSize={isGreaterThanMd ? '7xl' : '3xl'} color={'white'} fontFamily={'Aileron-regular'} fontWeight={"400"} letterSpacing='wider' textTransform={"uppercase"}>
                        {text3}
                    </Text>
                    <br />
                    <Button
                        onClick={onOpen}
                        fontFamily={'Aileron-bold'}
                        fontSize='sm'
                        bg='#FFF'
                        color='#5D7261'
                    >
                        Quiero ser contactado
                    </Button>
                    {isOpen ?
                        <ModalCard
                            isOpen={isOpen}
                            onClose={onClose}
                            title={"Quiero ser contactado"}
                            //idForm={"22b54777-673f-44b6-bfd8-043fee1a6931"}
                            idForm={"fcc8a6ec-50ca-4c31-a20c-fd689ec217dd"}
                            thankYouPage={"thankyoupage-agenda-cita"}
                        /> : null
                    }
                </Flex>
            </Flex>
        </Box>
    )
}

export default CTA