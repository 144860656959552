import React, {useState}from 'react'
import { 
    Flex, 
    Text,
    useMediaQuery, 
    useTheme,
    Grid,
    GridItem,
    Image,
    FormControl,
    FormLabel,
    Input, 
    Checkbox,
    Button,
    Link
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

const Contacto = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/images/exterior.png' }
    ];

    const postForm = () => {
        let data_send = {
            fields: [
              {
                name: "firstname",
                value: name,
              },
              {
                name: "lastname",
                value: lastName,
              },
              {
                name: "email",
                value: email,
              },
              {
                name: "phone",
                value: phone,
              },
            ],
            context: {
              ipAddress: "3.14.97.137",
              pageUri: "www.grupoguia.mx",
              pageName: "Grupo Guia | Ambienta 3070",
            },
          };
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data_send),
          };
          fetch(
            "https://api.hsforms.com/submissions/v3/integration/submit/5930840/fcc8a6ec-50ca-4c31-a20c-fd689ec217dd",
            // "fcc8a6ec-50ca-4c31-a20c-fd689ec217dd",
            requestOptions
          )
        .then((response) => response.json())
        .then((data_w) => {
            toast.success("¡Tus datos fueron enviados correctamente!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            setName("");
            setLastName("");
            setEmail("");
            setPhone("");
            window.open('https://grupoguia.mx/desarrollos/thankyoupage-agenda-cita', '_blank');
        });
    }

  return (
        <Flex
            marginTop={isGreaterThanMd?"4rem":"5rem"}
            flexDirection={"column"}
            color={"#545454"}
                        mb={20}
                        id='contacto'
                    >
                        <Grid
                            templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
                            px={isGreaterThanMd ? 0 : 8}
                            w={isGreaterThanMd ? "80%" : "90%"}
                            margin="auto"
                            pb={10}
                            gap="3em 1em"
                            fontWeight={600}
                        >
                            <GridItem w="100%" id='img'>
                                <Flex height={"100%"} justifyContent={"center"} alignItems={"center"}>
                                    <Image src={assets[0].imgUrl} width="500" height="500" alt='icon'/>
                                </Flex>
                            </GridItem>
                            <GridItem id='form'>
                                <Flex flexDirection={"column"} pl={10}>
                                    <Text
                                        fontSize={ isGreaterThanMd ? '50px' :'30px'}
                                        fontWeight={700}
                                        color={'#5D7261'}
                                        fontFamily={'Aileron-bold'}
                                        textTransform={"uppercase"}
                                    >
                                        Contacto
                                    </Text>
                                    <Text
                                        fontSize="23px"
                                        fontWeight={400}
                                        fontFamily={'Aileron-regular'}
                                        lineHeight={'27.6px'}
                                        mt={5}
                                        textAlign={{ base: "center", lg: "left" }}
                                    >
                                        Si te interesa saber más de Ambienta 3070, déjanos tus datos y uno de nuestros asesores se pondrá en contacto contigo a la brevedad posible. 
                                    </Text>
                                    <Flex justifyContent={"left"} id="contacto" mt={10} width={'100%'}>
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            postForm();
                                            }}>
                                            <Grid templateColumns={"repeat(2, 1fr)"} width='100%' maxW={'5xl'} mx='auto' gap={10} >
                                                <GridItem colSpan={2}>
                                                    <FormControl isRequired>
                                                    <FormLabel>Nombre</FormLabel>
                                                    <Input 
                                                        variant='flushed'  
                                                        value={name}
                                                        htmlSize={'100'}
                                                        onChange={(e) => setName(e.target.value)}
                                                        isRequired
                                                    />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem colSpan={2}>
                                                    <FormControl isRequired>
                                                    <FormLabel>Apellido</FormLabel>
                                                    <Input 
                                                        variant='flushed'
                                                        value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)} 
                                                        isRequired
                                                        />
                                                        </FormControl>
                                                </GridItem>
                                                <GridItem colSpan={isGreaterThanMd?1:2}>
                                                    <FormControl isRequired>
                                                    <FormLabel>Número de teléfono</FormLabel>
                                                    <Input 
                                                        variant='flushed' 
                                                        type='number' 
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        isRequired
                                                    />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem colSpan={isGreaterThanMd?1:2}>
                                                    <FormControl isRequired>
                                                    <FormLabel>Correo</FormLabel>
                                                    <Input 
                                                        variant='flushed' 
                                                        width={'100%'}
                                                        type='email' 
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        isRequired
                                                    />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem colSpan={2} textAlign={'left'} mt={4}>
                                                    <Flex>
                                                        <Checkbox mr={2}/>
                                                        <Text>
                                                            He leído y acepto el 
                                                            <Link href='https://grupoguia.mx/Aviso-de-privacidad' color='blue' textDecoration={'underline'} ml={2} isExternal>
                                                                Aviso de privacidad
                                                            </Link>
                                                        </Text>
                                                    </Flex>
                                                </GridItem>

                                                <GridItem colSpan={2} textAlign={'left'} mt={4}>
                                                    <Button type='submit'  color={"white"} bg={"#5D7261"} 
                                                        px={8} rounded={'none'}
                                                    >
                                                        Enviar
                                                    </Button>
                                                </GridItem>
                                            </Grid>
                                        </form>
                                    </Flex>
                                </Flex>
                            </GridItem>
                        </Grid>
                    </Flex>
  )
}

export default Contacto


