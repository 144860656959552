import React from 'react';
import { 
    Flex,
    Tab,
    Tabs,
    TabPanels,
    TabPanel,
    TabList,
    Grid,
    GridItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    TableContainer, 
    TableCaption,
    Text,
    useMediaQuery,
    useTheme,
    
} from '@chakra-ui/react';
import { list } from '../resource';
import TableMobile from './TableMobile';

const Planes = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [showPlans, setShowPlans] = React.useState(false);

    return ( 
        <Flex
            color={"#545454"}
            width={"100%"}
            id='planes'
        >
            <Grid
                templateColumns={"repeat(1, 1fr)"}
                w={"100%"}
                pl={"12%"}
                pb={8}
                pt={isGreaterThanMd ? 10 : 0}
                >
                <GridItem w="90%">
                <Flex flexDirection={"column"} mb={"1rem"} mt={0}>
                    <Flex justifyContent={"center"}>
                        <Text textTransform={"uppercase"} fontFamily={'Aileron-bold'} lineHeight={'89.2px'} fontWeight={700} fontSize={isGreaterThanMd?"50px":"30px"} color={"#5D7261"}>
                            Planes de pago
                        </Text>
                    </Flex>
                    </Flex>
                    {isGreaterThanMd ?
                        <>
                        {
                            
                            showPlans ? (
                                <Tabs variant='enclosed'>
                                    <TabList width={"100%"}  justifyContent={'center'} fontFamily={'Aileron-regular'}>
                                        <Tab id='tab1' fontSize={'22px'} _selected={{ bg: "#5D7261", color: "#fff", fontWeight: "700", fontSize:'22px', fontFamily: 'Aileron-bold' }}>Contado</Tab>
                                        <Tab id='tab2' fontSize={'22px'} _selected={{ bg: "#5D7261", color: "#fff", fontWeight: "700", fontSize:'22px', fontFamily: 'Aileron-bold' }}>Contado comercial</Tab>
                                        <Tab id='tab3' fontSize={'22px'} _selected={{ bg: "#5D7261", color: "#fff", fontWeight: "700", fontSize:'22px', fontFamily: 'Aileron-bold' }}>Plan guía</Tab>
                                        <Tab id='tab4' fontSize={'22px'} _selected={{ bg: "#5D7261", color: "#fff", fontWeight: "700", fontSize:'22px', fontFamily: 'Aileron-bold' }}>Financiamiento</Tab>
                                        <Tab id='tab5' fontSize={'22px'} _selected={{ bg: "#5D7261", color: "#fff", fontWeight: "700", fontSize:'22px', fontFamily: 'Aileron-bold' }}>Plan Hipotecario</Tab>
                                    </TabList>
                                    <TabPanels>
                                        {list.map((item, idx) => (
                                            <TabPanel key={idx}>
                                                <TableContainer height={"38rem"}>
                                                    <Table size='lg' >
                                                        <TableCaption fontFamily={'Aileron-regular'} fontWeight={300} fontSize={"14px"}>*Politica Comercial sujeta a cambio sin previo aviso y sujeto a disponibilidad.</TableCaption>
                                                        <Thead>
                                                            <Tr>
                                                                <Th color={"#5D7261"} fontSize={'24px'}  textTransform={"inherit"} fontFamily={'Aileron-bold'} fontWeight={700}>Descuento</Th>
                                                                <Th color={"#5D7261"} fontSize={'24px'} fontFamily={'Aileron-bold'} fontWeight={700}>{item.num}</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr>
                                                                <Th borderColor={'white'} fontSize={'20px'} fontFamily={'Aileron-regular'} fontWeight={600}>{item.pay}</Th>
                                                                
                                                            </Tr>
                                                            {item.tab.map((element, idx) => (
                                                                <Tr>
                                                                    <Th borderColor={'white'}  textTransform={"inherit"} fontSize={'24px'} fontFamily={'Aileron-regular'} fontWeight={400}>{element.item1}</Th>
                                                                    <Th borderColor={'white'}  textTransform={"inherit"} fontSize={'24px'} fontFamily={'Aileron-regular'} fontWeight={400}>{element.item2}</Th>
                                                                </Tr>
                                                            ))}
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </TabPanel>
                                        ))}
                                    </TabPanels>
                                </Tabs>
                            ): (
                                <Tabs variant='' borderBottom={'1px'}>
                                    <TabList width={"100%"}  justifyContent={'center'} fontFamily={'Aileron-regular'} onClick={() => setShowPlans(!showPlans)}>
                                        <Tab id='tab1' fontSize={'22px'}> {'> '}Contado</Tab>
                                        <Tab id='tab2' fontSize={'22px'}> {'> '}Contado comercial</Tab>
                                        <Tab id='tab3' fontSize={'22px'}> {'> '}Plan guía</Tab>
                                        <Tab id='tab4' fontSize={'22px'}> {'> '}Financiamiento</Tab>
                                        <Tab id='tab5' fontSize={'22px'}> {'> '}Plan Hipotecario</Tab>
                                    </TabList>
                                </Tabs>
                            )
                        }
                        </>
                        : (
                            showPlans ? (
                                <TableMobile />
                            ) : (
                                <Tabs variant='' flexDirection={"column"} alignItems={"center"}>
                                    <TabList width={"max-content"} flexDirection={"column"} margin={"auto"} fontFamily={'Aileron-regular'} onClick={() => setShowPlans(!showPlans)}>
                                        <Tab fontSize={"22px"}>{'> '}Contado</Tab>
                                        <Tab fontSize={"22px"}>{'> '}Contado comercial</Tab>
                                        <Tab fontSize={"22px"}>{'> '}Plan guía</Tab>
                                        <Tab fontSize={"22px"}>{'> '}Financiamiento</Tab>
                                        <Tab fontSize={"22px"} borderColor={"#FFF"}> {'> '}Plan Hipotecario</Tab>
                                    </TabList>
                                </Tabs>
                            )
                        )
                        
                    }
                </GridItem>
            </Grid>
        </Flex>
    );
}
 
export default Planes;