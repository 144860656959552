import React, { useEffect, useState } from 'react'
import Presentacion from '../../components/Presentacion';
import Description from '../../components/Description';
import Amenidades from '../../components/Amenidades';
import AmenidadesMb from '../../components/Amenidades/AmenidadesMb';
import Carousel from '../../components/Carousel';
import CTA from '../../components/CTA';
import Prototipos from '../../components/Prototipos';
import Planes from '../../components/Planes';
import Cercanias from '../../components/Cercanias';
import Desarrollador from '../../components/Desarrollador';
import Contacto from '../../components/Contacto';
import Footer from '../../components/Footer';
import ContactMobile from '../../components/ContactMobile';
import ButtonPage from '../../components/ButtonPage';
import ModalCard from '../../components/ModalCard';
import {
    Flex,
    Divider,
    useTheme,
    useMediaQuery,
    Modal,
    useDisclosure,
    Button,
    Box,
    Center,
} from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';

const Home = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure();
    const [isFb, setIsFb] = useState(false);

    useEffect(() => {
        // Obtén la URL actual
        const queryString = window.location.search;
        // Analiza la URL para obtener los parámetros
        const urlParams = new URLSearchParams(queryString);

        // Obten los valores de los parámetros que necesitas
        const param = urlParams.get('from');

        if (param) {
            setIsFb(true);
            setTimeout(() => {
                onOpen();
                onOpenDrawer();
            }, 3000);
        }
    }, [])

    return (
        <>
            {/* ===== BOTTOM MENU FOR MOBIL AND FACEBOOK USERS ===== */}
            {isFb ? (
                <Box
                    zIndex={4}
                    display={{ base: 'block', md: 'none' }}
                    position='fixed'
                    bottom='0px'
                    left='0px'
                    w='100%'
                    h='82px'
                    bg='#FFFFFF'
                    py={4}
                >
                    <Center>
                        <Button
                            w='75%'
                            size='lg'
                            bg='#179848'
                            _hover={{
                                bg: '#127337'
                            }}
                            leftIcon={<FaWhatsapp size='25px' />}
                            onClick={() => onOpenDrawer()}
                        >
                            Whatsapp
                        </Button>
                    </Center>
                </Box>
            ) : null}
            {/* ===== SHOW MODAL ONLY WHEN IS USER FROM FACEBOOK ===== */}
            <ModalCard
                isOpen={isOpen}
                onClose={onClose}
                title={'Envíanos un Whatsapp'}
                idFormHs={"77cffee9-0d25-4da5-b35f-0f934c0bff55"}
                isOpenDrawer={isOpenDrawer}
                onCloseDrawer={onCloseDrawer}
                facebook={isFb}
            />
            <Flex position={'fixed'} right={{ base: 2, md: "20px" }} bottom={5} zIndex={3}>
                <ButtonPage
                    idFormHs={"77cffee9-0d25-4da5-b35f-0f934c0bff55"}
                    thankYouPage={"thankyoupage-contactar-whatsapp"}
                    isImage={true}
                    facebook={isFb}
                    srcImg={'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/wa.svg'}
                    title={'Envíanos un Whatsapp'}
                />
            </Flex>
            <Presentacion />
            <Description />
            {
                isGreaterThanMd ?
                    <Amenidades /> : <AmenidadesMb />
            }
            <CTA
                ctaNum={0}
                text1={'Prepárate para hacer'}
                text2={' la inversión de tu vida'}
                text3={' a 500 m de la minerva'} />
            <Carousel />
            <CTA
                ctaNum={1}
                text1={'Siéntete libre de vivir'}
                text2={'bajo tus propias reglas'} />
            <Prototipos />
            <Planes />
            <Cercanias />
            <CTA
                ctaNum={2}
                text1={'Aquí empieza'}
                text2={'lo mejor de tu vida'} />
            <Desarrollador />
            {isGreaterThanMd ? <Contacto /> : <ContactMobile />}
            <Flex paddingLeft={"1.5rem"} paddingRight={"1.5rem"}>
                <Divider orientation='horizontal' />
            </Flex>
            <Footer />
        </>
    )
}

export default Home